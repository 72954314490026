import React, { useState } from 'react';
import lodashGet from 'lodash.get';
import { Card } from 'antd';
import { t1 } from 'translate';

import { timestampToDateTimeString } from 'common/utils/Date';
import { breadCrumb } from 'common/utils/string/index';
import FileViewer from 'schema-form/elements/attachments/core/file-viewer';

const getOrganizationName = (orgTypes, type) => {
  const findOrgName = (t) => {
    const org = orgTypes.find((org) => org.type == t);
    return org && org.name ? org.name : t1('undefined_organization');
  };

  return Array.isArray(type)
    ? type.map(findOrgName).join(', ')
    : findOrgName(type);
};

const AntdSearchResultCard = ({ items = [], orgTypes = [] }) => {
  const [expandedItemId, setExpandedItemId] = useState(null);

  const toggleExpand = (itemId) => {
    setExpandedItemId(expandedItemId === itemId ? null : itemId);
  };

  return (
    <div className={'d-flex d-flex-column'} style={{ gap: '12px' }}>
      {items.map((item) => {
        const itemId = lodashGet(item, 'id');
        const isExpanded = expandedItemId === itemId;

        const hasAttachments = lodashGet(item, 'reflection_attachments.length');

        const reflectionToOrgType = lodashGet(
          item,
          'reflection_send_to_organization_type',
          '',
        );
        const organizationName = getOrganizationName(
          orgTypes,
          reflectionToOrgType,
        );

        return (
          <Card
            key={itemId}
            style={{
              borderRadius: '10px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              cursor: 'pointer',
              minHeight: '200px',
            }}
          >
            <div onClick={() => toggleExpand(itemId)}>
              <div className="d-flex justify-content-end align-items-center">
                <span className="text-primary font-size-medium">
                  {timestampToDateTimeString(lodashGet(item, 'ts', ''))}
                </span>
              </div>

              <div className="d-flex justify-content-between align-items-center m-t-20">
                <div style={{ fontWeight: 'bold' }} className="font-size-large">
                  {isExpanded
                    ? lodashGet(item, 'reflection_name', t1('no_title'))
                    : breadCrumb(
                        lodashGet(item, 'reflection_name', t1('no_title')),
                        30,
                        true,
                      )}
                </div>
              </div>

              <div className="font-size-medium m-t-10 m-b-5">
                <strong>{t1('reflection_to_organization')}:</strong>{' '}
                {organizationName}
              </div>

              <div className="font-size-medium">
                <strong>{t1('item_content')}: </strong>
                {isExpanded ? (
                  <>
                    {lodashGet(
                      item,
                      'reflection_description',
                      t1('no_content'),
                    )}
                  </>
                ) : (
                  <>
                    {breadCrumb(
                      lodashGet(
                        item,
                        'reflection_description',
                        t1('no_content'),
                      ),
                      100,
                      true,
                    )}
                  </>
                )}
              </div>
            </div>

            {isExpanded && hasAttachments && (
              <div className="d-flex justify-content-center align-items-end m-t-15">
                <FileViewer
                  attachments={lodashGet(item, 'reflection_attachments') || []}
                  showDetail={true}
                />
              </div>
            )}

            {(hasAttachments ||
              breadCrumb(
                lodashGet(item, 'reflection_description', ''),
                100,
                true,
              ).includes('...') ||
              breadCrumb(
                lodashGet(item, 'reflection_name', ''),
                30,
                true,
              ).includes('...')) && (
              <div className="d-flex justify-content-center align-items-end m-t-15">
                <span
                  className="text-primary cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleExpand(itemId);
                  }}
                >
                  {isExpanded
                    ? t1('click_to_hide')
                    : t1('click_to_show_detail')}
                </span>
              </div>
            )}
          </Card>
        );
      })}
    </div>
  );
};

export default AntdSearchResultCard;
