import React, { Component } from 'react';
import { connect } from 'react-redux';
import endPoints from 'components/temis/endpoints';
import { t1 } from 'translate';
import { isSmallScreen } from 'common';
import { isOpenInapp } from 'common/browser';
import lastTimeFinishedSyncing from 'components/temis/report/syncer/lastTimeFinishedSyncing';
import { timestampToDateString } from 'common/utils/Date';
import Warning from 'components/common/Warning';
import ApiRequestBtnWithConfirmDialog from 'components/common/action-button/ApiRequestBtnWithConfirmDialog';

class ReportProgressSyncerButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      syncingItSelf: false,
    };
  }

  executeOnFailure = () => {
    this.setState({
      syncingItSelf: false,
    });
  };

  startSync = () => {
    this.setState({
      syncingItSelf: true,
    });
  };

  render() {
    const {
      disabled,
      syncingReport,
      lastTimeSyncing,
      params,
      apiSync,
      timeFinishedSyncing,
      handleAction,
      onRequestSuccessful,
    } = this.props;
    const { syncingItSelf } = this.state;

    const currentTs = new Date().getTime() / 1000;
    const time = syncingItSelf ? currentTs : lastTimeSyncing;
    const timeString = timestampToDateString(time, {
      showTime: true,
    });

    const url = apiSync || endPoints.sync_report_progress;
    const hideTimeSyncing = syncingReport || syncingItSelf || !lastTimeSyncing;

    const buttonLabel =
      syncingReport || syncingItSelf
        ? time
          ? `BC đang được đồng bộ từ ${timeString}...`
          : 'BC đang được đồng bộ'
        : 'Đồng bộ lại báo cáo mới nhất';

    return (
      <div className="d-flex align-items-center">
        {(timeFinishedSyncing || !hideTimeSyncing) && (
          <div
            className={`${
              isSmallScreen || isOpenInapp() ? 'text-center' : 'm-r-15'
            }`}
          >
            {lastTimeFinishedSyncing(timeFinishedSyncing || time)}
          </div>
        )}

        <div className="table-action-syn-content text-center">
          <ApiRequestBtnWithConfirmDialog
            handleAction={handleAction}
            className={'btn btn-primary btn-outline'}
            disabled={disabled || syncingReport || syncingItSelf}
            label={buttonLabel}
            loading={syncingReport || syncingItSelf}
            url={url}
            textConfirm={
              <>
                <Warning>
                  Quá trình đồng bộ sẽ mất thời gian. Quý thầy/cô có muốn đồng
                  bộ lại báo cáo?
                </Warning>
                <div className="m-t-15">
                  Sau khi đồng bộ, nếu chưa thấy dữ liệu mới nhất được cập nhật,
                  quý thầy/cô vui lòng tải lại trang (F5)
                </div>
              </>
            }
            params={params}
            actionHandlerCallback={this.startSync}
            executeOnFailure={this.executeOnFailure}
            onRequestSuccessful={onRequestSuccessful}
            showSnackbar={false}
          />
        </div>
      </div>
    );
  }
}

export default connect()(ReportProgressSyncerButton);
