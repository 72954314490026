import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import AttachmentViewer from 'components/common/forms/questions/open-ended/attachment-viewer';
import './style.scss';
import get from 'lodash/get';
import { t1 } from 'translate';
import { isSmallScreen } from 'common';
import { isOpenInapp } from 'common/browser';

import ListAttachmentsToDownloadAsTable from 'components/common/attachment/ListAttachmentsToDownloadAsTable';

const FileViewer = ({ attachments, showDetail = false }) => {
  const ext = get(attachments, '[0].ext');
  const isPdf = ext === 'pdf' || ext === 'PDF';

  if ((isOpenInapp() || isSmallScreen) && showDetail) {
    return <ListAttachmentsToDownloadAsTable attachments={attachments} />;
  }

  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <span onClick={showFull} className="ve-eye cursor-pointer" />
      )}
      renderFull={() => (
        <AttachmentViewer attachments={attachments} showHeader={false} />
      )}
      dialogKey="view-evidence"
      dialogOptionsProperties={{
        title: t1('uploaded_file_content'),
        width: '100%',
        handleClose: true,
        className: `view-evidence-dialog ${isPdf ? 'custom-for-pdf' : ''}`,
      }}
    />
  );
};

export default FileViewer;
