import './stylesheet.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { t1 } from 'translate';
import { Checkbox, Table, Button } from 'antd';
import { isSmallScreen } from 'common';
import { isOpenInapp } from 'common/browser';
import lodashEqual from 'lodash.isequal';

const setVisibilityColumns = (columns = [], col, visible) => {
  const columnsVisible = [];
  if (!columns || !columns.length) {
    return [];
  }

  columns.forEach((column) => {
    const hasChildren = column.children && column.children.length;

    columnsVisible.push({
      ...column,
      children:
        hasChildren && setVisibilityColumns(column.children, col, visible),
      visible: column.id === col.id ? visible : column.visible,
    });
  });

  return columnsVisible;
};

const filterVisibleColumns = (columns = []) => {
  const columnsVisible = [];
  if (!columns || !columns.length) {
    return [];
  }

  columns.forEach((column) => {
    const hasChildren = column.children && column.children.length;
    const hasRender = !!column.render;
    const hasChildrenVisible =
      hasChildren && filterVisibleColumns(column.children).length;

    if (column.visible && (hasChildrenVisible || hasRender)) {
      columnsVisible.push({
        ...column,
        children: hasChildrenVisible && filterVisibleColumns(column.children),
      });
    }
  });

  return columnsVisible;
};

export const getTotalVisibleColumns = (columns) => {
  let count = 0;
  if (!columns || !columns.length) {
    return count;
  }

  columns.forEach((column) => {
    const hasChildren = column.children && column.children.length;

    if (hasChildren) {
      count = count + getTotalVisibleColumns(column.children);
    } else {
      count = count + 1;
    }
  });

  return count;
};

const getIdsOfVisibleColumns = (visibleColumns) => {
  let columnIds = [];
  if (!visibleColumns || !visibleColumns.length) {
    return [];
  }

  visibleColumns.forEach((column) => {
    const hasChildren = column.children && column.children.length;

    columnIds.push(column.id);

    if (hasChildren) {
      columnIds = columnIds.concat(getIdsOfVisibleColumns(column.children));
    }
  });

  return columnIds;
};

const columnsConfig = (columns = [], updateColumnsConfig, allowRemove) => {
  const columnsWithConfig = [];
  if (!columns || !columns.length) {
    return [];
  }

  columns.forEach((column, index) => {
    const hasChildren = column.children && column.children.length;

    columnsWithConfig.push({
      ...column,
      title: (
        <label htmlFor={column.id} className="cursor-pointer">
          <Checkbox
            id={column.id}
            disabled={
              (typeof allowRemove == 'undefined' && index === 0) ||
              (typeof allowRemove !== 'undefined' && !allowRemove)
            }
            value={column.id}
            defaultChecked={true}
            onChange={(event) =>
              updateColumnsConfig(column, event.target.checked)
            }
          />
          <span className="m-l-5">{column.title}</span>
        </label>
      ),
      children:
        hasChildren &&
        columnsConfig(
          column.children,
          updateColumnsConfig,
          (typeof allowRemove == 'undefined' && index !== 0) ||
            (typeof allowRemove !== undefined && allowRemove),
        ),
    });
  });

  return columnsWithConfig;
};

const QuickConfig = ({ columns, changeVisibleColumns, className }) => {
  const [restoringDefault, setRestoringDefault] = useState(-1);
  const [showConfig, setShowConfig] = useState(false);
  const [renderTime, setRenderTime] = useState(Date.now());
  const [columnsVisible, setColumnsVisible] = useState(columns);
  const [curentColumnsVisible, setCurentColumnsVisible] = useState(null);
  const [defaultColumns, setDefaultColumns] = useState();
  const [columnsIids, setColumnsIids] = useState(null);

  useEffect(
    () => {
      if (!Array.isArray(columns) || !columns.length) {
        return;
      }
      const ids = getIdsOfVisibleColumns(columns);

      if (lodashEqual(ids, columnsIids)) {
        return;
      }

      setColumnsIids(ids);
      setColumnsVisible(columns);
      setDefaultColumns(columns);

      if (typeof changeVisibleColumns === 'function') {
        changeVisibleColumns(columns, ids);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [columns],
  );

  const updateColumnsConfig = useCallback(
    (col, show) => {
      if (
        !Array.isArray(curentColumnsVisible) ||
        !curentColumnsVisible.length
      ) {
        setCurentColumnsVisible(columnsVisible);
      }

      const cols = setVisibilityColumns(columnsVisible, col, show);
      setColumnsVisible(cols);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsVisible, changeVisibleColumns],
  );

  const applyConfiguredColumns = () => {
    if (typeof changeVisibleColumns === 'function') {
      const colsVisible = filterVisibleColumns(columnsVisible);
      const ids = getIdsOfVisibleColumns(colsVisible);

      changeVisibleColumns(colsVisible, ids);
    }

    setCurentColumnsVisible(null);
  };

  const resetToDefault = useCallback(
    () => {
      setRestoringDefault(0);
      setColumnsVisible(defaultColumns);

      const ids = getIdsOfVisibleColumns(defaultColumns);
      if (typeof changeVisibleColumns === 'function') {
        changeVisibleColumns(defaultColumns, ids);
      }

      setTimeout(() => {
        setRestoringDefault(0);

        setTimeout(() => {
          setRestoringDefault(-1);
          setRenderTime(Date.now());
        }, 200);
        setCurentColumnsVisible(null);
      }, 2000);
    },
    [defaultColumns, changeVisibleColumns, setCurentColumnsVisible],
  );

  return (
    <div className={`quick-config m-t-15 ${className}`}>
      <div className="quick-config__title">
        <h3 className="m-0">{t1('config_column_of_report')}</h3>

        <button
          className="btn btn-primary btn-small m-l-10"
          onClick={() => setShowConfig(!showConfig)}
        >
          {!showConfig ? t1('show_report_config') : t1('hide_report_config')}
        </button>
      </div>

      <div className={`${showConfig ? '' : 'd-none'}`}>
        <Table
          key={renderTime}
          className="table-config-columns learn-default"
          scroll={{
            x: isSmallScreen,
          }}
          columns={columnsConfig(columnsVisible, updateColumnsConfig)}
          bordered
        />

        <div
          className={`d-flex align-items-center m-t-30 ${
            isSmallScreen || isOpenInapp() ? 'justify-content-center' : null
          }`}
        >
          {typeof changeVisibleColumns === 'function' && (
            <>
              <Button
                disabled={curentColumnsVisible == null}
                className="btn btn-primary m-l-20"
                onClick={applyConfiguredColumns}
              >
                {t1('apply_configured_columns')}
              </Button>
            </>
          )}

          <Button
            className="btn btn-secondary m-l-10"
            loading={restoringDefault !== -1}
            onClick={resetToDefault}
          >
            {(() => {
              switch (restoringDefault) {
                case -1: {
                  return t1('reset_to_default');
                }
                case 0: {
                  return t1('restored');
                }
                default: {
                  return t1('restoring');
                }
              }
            })()}
          </Button>
        </div>

        <hr />
      </div>
    </div>
  );
};

export default QuickConfig;
