import React from 'react';
import lodashGet from 'lodash.get';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import OrganizationsOrPhongBan from 'components/admin/group/common/OrganizationsOrPhongBanInResultTable';
import { getUserBirthday } from 'components/admin/user/utils';
import { t1 } from 'translate';
import { timestampToDateTimeString } from 'common/utils/Date';
import FileViewer from 'schema-form/elements/attachments/core/file-viewer';

const AntdSearchResult = ({ items, showUserInfo = false, orgTypes }) => {
  //chỉ show các cột thông tin của cá nhân nào đang kiến nghị, khi ở màn admin/report <=> showUserInfo = true
  let columns = [
    showUserInfo && {
      title: t1('code'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => <div>{lodashGet(item, 'u.code')}</div>,
    },
    showUserInfo && {
      title: t1('name'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => <div>{lodashGet(item, 'u.name')}</div>,
    },
    showUserInfo && {
      title: t1('birthday'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => getUserBirthday(lodashGet(item, 'u.birthday')),
    },
    showUserInfo && {
      title: t1('mail'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => lodashGet(item, 'u.mail', ''),
    },
    showUserInfo && {
      title: t1('phone'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => lodashGet(item, 'u.phone', ''),
    },
    showUserInfo && {
      title: t1('organization'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => {
        const userOrganizations = lodashGet(item, 'u.user_organizations');

        if (!Array.isArray(userOrganizations) || !userOrganizations.length) {
          return '';
        }

        return (
          <OrganizationsOrPhongBan
            item={lodashGet(item, 'u')}
            attr={'user_organizations'}
            showParentsInfo
            showCode
          />
        );
      },
    },
    !showUserInfo && {
      title: t1('reflection_to_organization'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => {
        const reflectionToOrgType = lodashGet(
          item,
          'reflection_send_to_organization_type',
          '',
        );
        const orgList = orgTypes.find(
          (org) => org.type == String(reflectionToOrgType),
        ); //map to find the orgName

        return <div>{orgList ? orgList.name : null}</div>;
      },
    },
    {
      title: t1('reflection_name'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => (
        <div style={{ maxWidth: 300 }}>
          {lodashGet(item, 'reflection_name', '')}
        </div>
      ),
    },
    {
      title: t1('reflection_description'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => (
        <div style={{ maxWidth: 300 }}>
          {lodashGet(item, 'reflection_description', '')}
        </div>
      ),
    },
    {
      title: t1('reflection_attachments'),
      className: 'text-center',
      render: (item) => {
        if (!lodashGet(item, 'reflection_attachments.length')) {
          return null;
        }

        return (
          <FileViewer
            attachments={lodashGet(item, 'reflection_attachments') || []}
          />
        );
      },
    },
    {
      title: t1('reflection_time'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => timestampToDateTimeString(lodashGet(item, 'ts', '')),
    },
  ].filter(Boolean);

  return (
    <div className="table-result" style={{ width: '100%' }}>
      <CommonAntdTable
        bordered
        rowKey="id"
        size="middle"
        pagination={false}
        childrenColumnName={null}
        style={{ background: 'white' }}
        columns={columns}
        className="learn-default"
        dataSource={Array.isArray(items) ? items : []}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default AntdSearchResult;
