import { change } from 'redux-form';
import lodashGet from 'lodash.get';
import Store from 'store';
import { input } from 'components/common/forms/schema-fields';
import DatePicker from 'schema-form/elements/date-picker';
import { dateGreaterThan, dateLessThan, required } from 'common/validators';
import { t1 } from 'translate';
import {
  caphoc,
  listSchoolYear,
  targetUser,
  trainingModel,
} from 'components/common/elements';
import { simpleOrganization } from 'components/admin/organization/schema/elements';
import { userGradeOptions } from 'configs/constants/user';
import { TRAINING_MODEL } from 'components/taphuan/configs';
import { organizations } from 'components/admin/organization/schema/elements';
import subTypes from 'configs/constants/org-sub-types';

const schema = (formid, values, localStep, xpath, props) => {
  const isBgd = lodashGet(props, 'isBgd');
  const deployed = lodashGet(props, 'deployed');
  const currentCaps = lodashGet(props, 'node.caps');
  const currentOrganizations = lodashGet(props, 'node.apply_organizations');
  const trainingModelChosen =
    lodashGet(values, 'training_model') || TRAINING_MODEL.DAI_TRA;

  const showOrgLttu = isBgd && trainingModelChosen === TRAINING_MODEL.COT_CAN;

  return {
    name: input('Tên đợt đào tạo (*)', true, {
      classWrapper: 'col-md-12',
    }),
    start_date: {
      type: DatePicker,
      getStartDate: true,
      validate: [
        required(t1('start_time_cannot_be_empty')),
        dateLessThan(values.end_date, t1('start_time_must_be_before_end_time')),
      ],
      hintText: t1('start_time'),
      floatingLabelText: 'Thời gian bắt đầu (*)',
      classWrapper: 'col-md-6',
      fullWidth: true,
      // disabled: deployed,
    },
    end_date: {
      type: DatePicker,
      getEndDate: true,
      validate: [
        required(t1('end_time_cannot_be_empty')),
        dateGreaterThan(
          values.start_date,
          t1('end_time_must_be_after_start_time'),
        ),
      ],
      hintText: t1('end_time'),
      floatingLabelText: 'Thời gian kết thúc (*)',
      classWrapper: 'col-md-6',
      // disabled: deployed,
      fullWidth: true,
    },
    target_user: targetUser({
      type: 'radio',
      floatingLabelText: 'Đối tượng (*)',
      validate: [required()],
      disabled: deployed,
      classWrapper: 'col-md-3',
    }),
    caps: caphoc({
      floatingLabelText: 'Cấp học *',
      validate: [required()],
      defaultValue: userGradeOptions().map((option) => option.value),
      disabledOptions: deployed ? currentCaps : [],
      classWrapper: 'col-md-6',
    }),
    training_model: trainingModel({
      floatingLabelText: 'Mô hình đào tạo (*)',
      validate: [required()],
      disabled: deployed,
      classWrapper: 'col-md-3',
      onChange: () => {
        Store.dispatch(change(formid, 'apply_organizations', []));
      },
    }),
    school_year: listSchoolYear({
      floatingLabelText: 'Năm học (*)',
      multiple: false,
      validate: [required()],
      // disabled: deployed,
      classWrapper: 'col-md-12',
    }),
    use_cot_can_as_teacher: {
      type: 'checkbox',
      label: t1('use_cot_can_as_teacher'),
      classWrapper: 'col-md-12',
      disabled: deployed,
    },
    allow_other_target_user_to_learn: {
      type: 'checkbox',
      label: t1('allow_other_target_user_to_learn'),
      classWrapper: 'col-md-12',
      disabled: deployed,
    },
    apply_organizations: showOrgLttu
      ? simpleOrganization(
          {
            floatingLabelText: `${t1('apply_for_organizations')} (*)`,
            multiple: true,
          },
          {
            value: {
              sub_type: [subTypes.TAPHUAN_SUBTYPE_LTTU],
            },
          },
          true,
        )
      : organizations({
          renderKey: `organization-for-${trainingModelChosen}`,
          subTypes: [
            subTypes.TAPHUAN_SUBTYPE_BO_GD,
            subTypes.TAPHUAN_SUBTYPE_SO_GD,
            subTypes.TAPHUAN_SUBTYPE_PHONG_GD,
          ],
          pIids: [],
          defaultValue: [],
          populateDefaultValue: false,
          multiple: true,
          label: `${t1('apply_for_organizations')} (*)`,
          defaultValidate: [required(t1('organizations_can_not_empty'))],
          validate: [required(t1('organizations_can_not_empty'))],
          classWrapper: 'col-md-12',
          // readOnly: deployed,
          disabledOptions: deployed ? currentOrganizations : undefined,
        }),
    allow_other_cap_to_learn: {
      type: 'checkbox',
      label: t1('allow_other_cap_to_learn'),
      classWrapper: 'col-md-12',
      disabled: deployed,
    },
  };
};

const ui = (step, values) => {
  return [
    {
      id: 'default',
      fields: [
        'name',
        'school_year',
        'start_date',
        'end_date',
        'training_model',
        'target_user',
        'caps',
        ...(values.training_model === TRAINING_MODEL.DAI_TRA
          ? ['use_cot_can_as_teacher']
          : []),
        'allow_other_target_user_to_learn',
        'apply_organizations',
        ...(values.caps && values.caps.length === 1
          ? ['allow_other_cap_to_learn']
          : []),
      ],
    },
  ];
};

export default {
  schema,
  ui,
};
