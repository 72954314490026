import React from 'react';
import { t1 } from 'translate';

import schema from './schema';
import NodeNew from 'components/admin/node/new';
import fetchData from 'components/common/fetchData';
import { loadingStatuses } from 'configs/constants';
import Loading from 'components/common/loading';

const AddNewReflect = ({
  requestSuccessful,
  userInfo = {},
  organizationSubTypes,
  loadingStatus,
  searchFormId,
}) => {
  const { name, mail, phone, birthday } = userInfo || {};

  if (loadingStatus !== loadingStatuses.FINISHED) {
    return <Loading />;
  }

  return (
    <NodeNew
      schema={schema}
      node={{ name, mail, phone, birthday }}
      searchFormId={searchFormId}
      alternativeApi={'/reflect/api/new'}
      requestSuccessful={requestSuccessful}
      submitLabel={t1('send_reflect')}
      organizationSubTypes={organizationSubTypes}
    />
  );
};

export default fetchData(() => ({
  baseUrl:
    '/organization/api/get-tree-sub-type-of-organization-by-current-organization',
  propKey: 'organizationSubTypes',
  fetchCondition: true,
  refetchCondition: () => false,
}))(AddNewReflect);
