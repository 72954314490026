import React from 'react';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import { connect } from 'react-redux';

import apiUrls from 'components/temis/endpoints';
import DetailOnDialog from 'components/common/detail-on-dialog';
import ButtonSecondary from 'components/common/button/ButtonSecondary';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import AddNewReflect from './addNewReflect';
import ResultComponent from './ResultComponent';

const CommentAndReflect = (props) => {
  const { userInfo, domainInfo } = props;
  const orgTypes = lodashGet(domainInfo, 'school.org_types', {});
  const searchFormId = 'search_comment_and_reflect';
  const hiddenFields = {
    user_iid: lodashGet(props, 'userInfo.iid', null),
  };

  const renderResultsComponent = (items) => (
    <div>
      <h2 className={'text-center'}>{t1('my_list_comment_and_reflect')}</h2>
      <DetailOnDialog
        renderPreview={({ showFull }) => (
          <div className={'d-flex justify-content-start'}>
            <ButtonSecondary onClick={showFull}>
              {t1('send_comment_and_reflect')}
            </ButtonSecondary>
          </div>
        )}
        renderFull={({ closeDialog }) => (
          <AddNewReflect
            requestSuccessful={closeDialog}
            userInfo={userInfo}
            searchFormId={searchFormId}
          />
        )}
        dialogKey="add_new_reflect"
        dialogOptionsProperties={{
          handleClose: true,
          modal: true,
          height: '100%',
          title: t1('add_new_reflect'),
        }}
      />
      <ResultComponent items={items} orgTypes={orgTypes} />
    </div>
  );

  return (
    <SearchWrapper
      showResult
      showResultsWhenSubmitSucceeded
      alternativeApi={apiUrls.search_comment_and_reflect}
      hiddenFields={hiddenFields}
      renderResultsComponent={renderResultsComponent}
      showSearchButton={false}
      formid={searchFormId}
    />
  );
};

const mapStateToProps = (state) => ({
  domainInfo: state.domainInfo,
  userInfo: lodashGet(state, 'user.info'),
});

export default connect(mapStateToProps)(CommentAndReflect);
