import React from 'react';
import { isOpenInapp } from 'common/browser';
import { isSmallScreen } from 'common';
import AntdSearchResultCard from 'components/temis/report/reflect/mobile/Result';
import Results from 'components/temis/report/reflect/Results';

const ResultComponent = ({ items, orgTypes }) => {
  return (
    <div className={'m-t-5'}>
      {isOpenInapp() || isSmallScreen ? (
        <AntdSearchResultCard items={items} orgTypes={orgTypes} />
      ) : (
        <Results items={items} orgTypes={orgTypes} />
      )}
    </div>
  );
};

export default ResultComponent;
