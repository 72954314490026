import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from './schema';
import TableResults from './TableResults';
import apiEndpoints from '../../../endpoints';
import { isSmallScreen } from 'common';
import { isOpenInapp } from 'common/browser';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import ExportData from 'components/common/export-data';
import ReportProgressSyncerButton from '../../syncer';
import withOutOfDeadlineAssessment from 'components/common/scholastic-config/withOutOfDeadlineAssessment';
import lodashGet from 'lodash.get';
import { generateReportName } from 'components/temis/utils';
import { ReloadDataSyncerButtonComponent } from 'components/temis/report/utils';
import Tabs from 'components/common/tabs';
import { t1 } from 'translate';

const renderResultsComponent = (
  resultData = [],
  {
    isResultForCBQL,
    isResultForMN,
    exportProps = {},
    userInfo,
    tcnn,
    isPlanDevelopment,
    isReportOfTruong,
    isOutOfDeadlineAssessment,
    formid = '',
    reportForNcbdtx,
    customFilter,
  },
  objects,
  searchValues = {},
  resultId,
) => {
  const organizations = lodashGet(searchValues, 'organizations') || [];

  const { last_time_finished_syncing, ts_started } = objects;

  const hasData = Array.isArray(resultData) && resultData.length;

  return (
    <>
      <div className="m-b-15">
        <div
          className={`${
            !(isSmallScreen || isOpenInapp())
              ? 'd-flex justify-content-end align-items-center d-sm-flex-column d-sm-align-items-end'
              : null
          }`}
        >
          <div className="report-result__syncer">
            {customFilter ? (
              <ReloadDataSyncerButtonComponent
                ts={last_time_finished_syncing}
                ts_started={ts_started}
                formid={formid}
                resultId={resultId}
              />
            ) : (
              <ReportProgressSyncerButton
                formId={formid}
                params={{ organizations, isResultForMN: isResultForMN ? 1 : 0 }}
                disabled={isOutOfDeadlineAssessment}
                lastTimeSyncing={(objects || {}).last_time_finished_syncing}
                syncingReport={(objects || {}).syncing_report}
              />
            )}
          </div>

          {!!hasData && (
            <div
              className={`report-result__export-excel m-l-10 ${
                isSmallScreen || isOpenInapp()
                  ? 'text-center m-t-20 m-b-20'
                  : null
              }`}
            >
              <ExportData
                resultId={resultId}
                apiExport={
                  isPlanDevelopment
                    ? apiEndpoints.export_summary_report_ncbdtx_by_tcnn
                    : apiEndpoints.export_summary_report_criteria_with_the_lowest_ranking_level
                }
                buttonText="Xuất excel"
                params={{
                  ...(tcnn ? { tcnn: tcnn } : {}),
                  ...(isResultForCBQL ? { isResultForCBQL: 1 } : {}),
                  isResultForMN: isResultForMN ? 1 : 0,
                }}
                fileName={generateReportName(
                  exportProps.fileName,
                  lodashGet(objects, 'organization.name'),
                )}
                tableExport={
                  <TableResults
                    skipFormatNumber={true}
                    dataResult={resultData}
                    isResultForCBQL={isResultForCBQL}
                    isResultForMN={isResultForMN}
                    tcnn={tcnn}
                    defaultExpandAllRows
                    isPlanDevelopment={isPlanDevelopment}
                    isReportOfTruong={isReportOfTruong}
                    reportForNcbdtx={reportForNcbdtx}
                  />
                }
              />
            </div>
          )}
        </div>

        {hasData ? (
          <TableResults
            key={last_time_finished_syncing || resultId}
            dataResult={resultData}
            isResultForCBQL={isResultForCBQL}
            isResultForMN={isResultForMN}
            tcnn={tcnn}
            isPlanDevelopment={isPlanDevelopment}
            isReportOfTruong={isReportOfTruong}
            reportForNcbdtx={reportForNcbdtx}
          />
        ) : (
          <SimpleNoResult text="Không có dữ liệu" />
        )}
      </div>
    </>
  );
};

const getAlternativeApi = (tabReport, isPlanDevelopment) => {
  if (tabReport === 'custom') {
    return isPlanDevelopment
      ? apiEndpoints.summary_report_ncbdtx_with_custom_filter_by_tcnn
      : apiEndpoints.summary_report_criteria_with_the_lowest_ranking_level_with_custom_filter;
  }

  return isPlanDevelopment
    ? apiEndpoints.summary_report_ncbdtx_by_tcnn
    : apiEndpoints.summary_report_criteria_with_the_lowest_ranking_level;
};

const BDTXReportSearch = ({
  tcnn,
  isPlanDevelopment,
  isResultForCBQL,
  userInfo,
  exportProps,
  isResultForMN,
  isReportOfTruong,
  formId = '',
  reportForNcbdtx,
  autoGetDataWithoutScholastic,
  isOutOfDeadlineAssessment,
}) => {
  const [tabReport, setTabReport] = React.useState('default');
  const defaultReport = tabReport === 'default';

  const formid = `${formId}_${tabReport}`;

  return (
    <>
      <div className="container">
        <Tabs
          type="card"
          value={tabReport}
          onActive={setTabReport}
          tabs={[
            {
              value: 'default',
              label: t1('temis_tab_view_a_report_default'),
              content: null,
            },
            {
              value: 'custom',
              label: t1('temis_tab_view_a_report_custom'),
              content: null,
            },
          ]}
        />
      </div>

      <SearchWrapper
        key={formid}
        formid={formid}
        schema={schema}
        classFormFilter={'container'}
        hiddenFields={{
          ...(tcnn ? { tcnn: tcnn } : {}),
          ...(isResultForCBQL ? { isResultForCBQL: 1 } : {}),
          ...(autoGetDataWithoutScholastic ? { without_scholastic: 1 } : {}),
          isResultForMN: isResultForMN ? 1 : 0,
        }}
        alternativeApi={getAlternativeApi(tabReport, isPlanDevelopment)}
        customFilter={!defaultReport}
        renderResultsComponent={renderResultsComponent}
        isResultForCBQL={isResultForCBQL}
        isResultForMN={isResultForMN}
        hideSubmitButton={defaultReport}
        showResult
        showResultsWhenSubmitSucceeded={defaultReport}
        autoSearchWhenStart={defaultReport}
        autoSearchWhenValuesChange={defaultReport}
        userInfo={userInfo}
        tcnn={tcnn}
        isPlanDevelopment={isPlanDevelopment}
        isReportOfTruong={isReportOfTruong}
        exportProps={exportProps}
        reportForNcbdtx={reportForNcbdtx}
        isOutOfDeadlineAssessment={isOutOfDeadlineAssessment}
      />
    </>
  );
};

export default withOutOfDeadlineAssessment(BDTXReportSearch);
